@import url('animations.css');

@font-face {
	font-family: Macherie;
	src: url('./fonts/Macherie/macherie.otf');
}
@font-face {
	font-family: Aileron;
	font-weight: 100;
	src: url('./fonts/Aileron/Aileron-Regular.otf');
}
@font-face {
	font-family: Aileron;
	font-weight: 400;
	src: local('Aileron-Light'), url('./fonts/Aileron/Aileron-Light.otf');
}
@font-face {
	font-family: Aileron;
	font-weight: 600;
	src: local('Aileron-SemiBold'), url('./fonts/Aileron/Aileron-SemiBold.otf');
}
@font-face {
	font-family: Aileron;
	font-weight: 700;
	src: local('Aileron-Bold'), url('./fonts/Aileron/Aileron-Bold.otf');
}

:root {
	--serif: Macherie, serif;
	--sans-serif: Aileron, Arial, sans-serif;
	--white: hsl(0, 0%, 100%);
	--dark-gray: hsl(0, 0%, 15%);
	--khaki: hsl(27, 65%, 87%);
	--gradient: linear-gradient(
		180deg,
		hsla(0, 0%, 85%, 0) 0%,
		hsla(27, 65%, 87%, 0.3) 100%
	);
	--fs-xl: 8rem;
	--fs-heading: 6rem;
	--fs-sub-heading: 4rem;
	--fs-text-large: 3rem;
	--fs-text-small: 0.88rem;
	--fw-700: 700;
	--fw-600: 600;
	--fw-400: 400;
	--fw-100: 100;
	--transition: 300ms ease-in-out;
}

html {
	box-sizing: border-box;
	font-size: 100%;
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
}
::-webkit-scrollbar {
	width: 0.47em;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.463);
	transition: var(--transition);
	border-radius: 36px;
}
*,
*::before,
*::after {
	margin: 0;
	box-sizing: inherit;
}
a {
	text-decoration: none;
	color: var(--white);
}
body {
	background: var(--dark-gray);
	color: var(--white);
	font-family: var(--sans-serif);
}
.noscroll {
	overflow: hidden;
}

.star {
	content: url(img/star.png);
	height: 3.75em;
	width: 3.75em;
	animation: rotate 10000ms linear infinite;
}

.header {
	background: linear-gradient(hsla(0, 0%, 0%, 0.5), hsla(0, 0%, 0%, 0.5)),
		url(img/background.png);
	background-size: cover;
	background-position: center;
	min-height: 100vh;
}

.header .overlay {
	height: 100vh;
	width: 100%;
	position: fixed;
	top: 3.56em;
	left: 0;
	bottom: 0;
	right: 0;
	background: linear-gradient(black, transparent);
	z-index: 1;
	opacity: 0;
}

.section-header {
	position: relative;
}
.section-header .navbar {
	width: 100%;
	position: fixed;
	top: 0;
	padding: 30px 50px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	transition: var(--transition);
	z-index: 5;
}
.section-header .navbar.sticky {
	background: hsla(0, 0%, 0%, 0.536);
	padding: 20px 50px;
	backdrop-filter: blur(5px);
}
.navbar .logo {
	/* flex-grow: 1; */
	font-family: var(--serif);
	font-size: 1.5rem;
	font-size: clamp(1.5rem, 1.43rem + 0.3vw, 1.7rem);
}
.section-header .place-holder {
	flex-grow: 1;
}
.navbar .links {
	display: flex;
	gap: 60px;
}
.navbar li {
	position: relative;
	list-style-type: none;
}
.navbar a:not(a[href='/']) {
	font-size: var(--fs-text-small);
	font-weight: 100;
	transition: all var(--transition);
	color: hsla(0, 0%, 100%, 0.8);
}
.navbar a::before {
	position: absolute;
	height: 1px;
	width: 0;
	content: '';
	background-color: var(--white);
	bottom: -7px;
	transition: var(--transition);
}
.navbar a:hover {
	color: var(--white);
}
.navbar a:not(a[href='/']):hover::before {
	width: 100%;
}

.slide-in {
	margin-top: 6rem;
}
.slide-out {
	margin-top: -20rem;
}
.section-header .mobile-menu {
	margin-top: 6rem;
	padding: 2rem;
	width: calc(100% - 5rem);
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--white);
	z-index: 5;
	text-align: center;
	opacity: 0;
}
.section-header nav.mobile-menu a {
	margin: 1.5rem 0;
	display: block;
	font-size: var(--fs-text-small);
	font-size: var(--fw-700);
	color: var(--dark-gray);
}

/* Hamburger Menu */
.btn-mobile-menu {
	visibility: hidden;
	flex-grow: 1;
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;
}
.btn-mobile-menu span {
	height: 0.0625em;
	width: 2em;
	background: rgb(255, 255, 255);
	display: block;
	transition: all 300ms ease-in-out;
}
.btn-mobile-menu span:not(:last-child) {
	margin-bottom: 7px;
}

.open-menu span:first-child {
	transform: rotate(45deg);
	transform-origin: 37%;
}
.open-menu span:last-child {
	transform: rotate(-45deg);
	transform-origin: 22%;
}

/* Hero Section */
.hero-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.hero-container .star {
	position: absolute;
	top: -3.75em;
	left: -3.75em;
}
.hero-container .hero-title {
	font-family: var(--serif);
	font-size: 3rem;
	font-size: clamp(3rem, 2rem + 4.44vw, 6rem);
}
.hero-container p {
	margin: 0;
	text-transform: uppercase;
}
.hero-container .hero-sub-title {
	position: absolute;
	right: 0;
	top: 6em;
	font-size: 0.625rem;
	font-size: clamp(0.625rem, 0.54rem + 0.37vw, 0.875rem);
	font-weight: 700;
	color: var(--khaki);
}
.hero-container .hero-caption {
	width: 13.69em;
	margin-top: 29px;
	font-size: 0.625rem;
	font-size: clamp(0.625rem, 0.54rem + 0.37vw, 0.875rem);
	font-weight: 600;
}

/* Main */
.main {
	padding: 0 122px 0;
}
.main__title {
	margin-bottom: 20px;
	height: auto;
	display: flex;
	align-items: center;
}
.main__title h1 {
	display: inline;
	margin-right: 12px;
	font-family: var(--serif);
	font-size: 2rem;
	font-size: clamp(2rem, 1.33rem + 2.96vw, 4rem);
}

/* About Section */
.section-about {
	/* height: 100vh; */
	margin-top: 70px;
	display: grid;
	grid-gap: 15px;
	grid-template-areas:
		'section-about__title section-about__img'
		'section-about__text section-about__img'
		'section-about__bonus-text section-about__bonus-text';
}
.section-about__title {
	grid-area: section-about__title;
}
.section-about__text {
	grid-area: section-about__text;
	text-transform: uppercase;
	font-size: 1.5rem;
	font-size: clamp(1.5rem, 1.167rem + 1.48vw, 2.5rem);
	font-weight: 600;
}
.section-about__bonus-text {
	position: relative;
	top: -1.25em;
	padding: 1.5rem;
	margin: 0 -122px 0 -122px;
	overflow: hidden;
	text-align: center;
	grid-area: section-about__bonus-text;
	text-transform: uppercase;
	font-size: 0.875rem;
	font-size: clamp(
		0.875rem,
		0.8309859154929577rem + 0.18779342723004694vw,
		1rem
	);
	font-weight: 600;
	background: hsl(0, 0%, 12%);
}
.section-about__bonus-text img {
	position: absolute;
	right: -4.0625em;
	bottom: -4.4375em;
	transform: rotate(-80deg);
	opacity: 0.3;
	grid-row: 1/3;
}
.section-about__img {
	grid-area: section-about__img;
	height: 30.5em;
	width: 30.5em;
	margin-bottom: 3rem;
	display: inline-block;
	object-fit: cover;
	border-radius: 50%;
}

/* Design Section */
.section-design {
	margin-top: 49px;
}
.section-design .content .sub-heading-design h1 {
	margin-bottom: 39px;
}
.section-design .main__title {
	margin-bottom: 70px;
}
.project-designs {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 50px;
}
.design {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
}
.design-1 {
	background: url(img/crop-design-1.png);
}
.design-2 {
	background: url(img/crop-design-2.png);
}
.design-3 {
	background: url(img/design-3.png);
}
.img-container-parent {
	width: 51.25em;
	height: 19.5em;
	overflow: hidden;
}
.img-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	transition: var(--transition);
}
.img-container-parent:hover > .img-container {
	transform: scale(1.1);
}
.img-container .view-design {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 0.625rem;
	font-size: clamp(0.625rem, 0.5rem + 0.56vw, 1rem);
	transform: translate(-50%, -10%);
	opacity: 0;
	transition: var(--transition);
	z-index: 3;
}
.img-container .view-design:hover {
	text-decoration: underline;
}
.img-container:hover > .view-design {
	opacity: 1;
	transform: translate(-50%, -50%);
}
.gradient-hover {
	position: absolute;
	height: 100%;
	width: 100%;
	background: linear-gradient(hsla(0, 0%, 32%, 0.3), hsla(0, 0%, 0%, 0.8));
	transition: var(--transition);
	opacity: 0;
}
.img-container:hover > .gradient-hover {
	opacity: 1;
}
.star1 {
	content: url(img/star1.png);
	width: 11.88em;
	height: 11.88em;
}

.star1-normal {
	animation: rotate 10000ms linear infinite;
}
.star1-reverse {
	animation: rotate 10000ms linear infinite;
}

/* Message Section */
.message-me h1 {
	margin: 100px 0 40px 0;
	font-size: 3.75rem;
	font-size: clamp(3.75rem, 3rem + 3.33vw, 6rem);
}
.message-me h1 span {
	color: var(--khaki);
}
.message-me a.btn {
	position: relative;
	padding: 5px 15px;
	border: 3px solid var(--white);
	border-radius: 36px;
	font-size: 2rem;
	font-size: clamp(2rem, 1.67rem + 1.48vw, 3rem);
	font-family: var(--serif);
	transition: color var(--transition);
}
.message-me a.btn span {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 0;
	border-radius: 36px;
	background-color: var(--khaki);
	transition: width 300ms ease-in-out;
	z-index: -1;
}
.message-me a.btn:hover span {
	width: 100%;
}
.message-me a.btn:hover {
	color: var(--dark-gray);
}

/* Footer Section */
.footer {
	padding: 30px 122px;
}
.line-star {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 18px;
}
.section-footer .line-star hr {
	height: 0.125em;
	width: 100%;
	background-color: var(--white);
}
.section-footer li {
	margin-right: 19px;
	position: relative;
	list-style-type: none;
	display: inline;
}
.section-footer a.accounts {
	display: inline-block;
	font-size: 0.75rem;
	font-size: clamp(0.75rem, 0.71rem + 0.19vw, 0.875rem);
	font-weight: 400;
	color: hsla(0, 0%, 100%, 0.8);
	transition: color var(--transition);
}

.section-footer .accounts:hover {
	color: var(--white);
}

/* Tablet Viewport 1280px */
@media (max-width: 80em) {
	.section-header .navbar {
		justify-content: space-between;
	}
	.navbar .logo {
		flex-grow: 0;
	}
	.navbar .btn-mobile-menu {
		flex-grow: 0;
	}
	.main {
		padding: 0 60px 0;
	}
	.section-about__bonus-text {
		margin: 0 -60px 0 -60px;
	}
	.project-designs {
		gap: 55px;
	}
	.star1 {
		display: none;
	}
	.star {
		height: 50px;
		width: 50px;
	}
	.hero-container .hero-sub-title {
		top: 5.5em;
	}
	.hero-container .hero-caption {
		margin-top: 15px;
	}
	.main .section-about {
		margin-top: 70px;
		grid-template-areas:
			'section-about__title'
			'section-about__img'
			'section-about__text'
			'section-about__bonus-text';
	}
	.main .section-about__img {
		height: 21.88em;
		width: 21.88em;
		margin: 0 auto;
	}
	.main .section-about__text {
		margin-top: 1rem;
		margin-bottom: 20px;
	}
	.section-about__bonus-text {
		top: 1.25em;
	}

	.main .img-container {
		width: 100%;
	}

	.footer {
		padding: 30px 60px;
	}
}

/* Mobile Viewport 600px */
@media (max-width: 37.5em) {
	html {
		scroll-padding-top: 80px;
	}
	.main {
		padding: 0 30px;
	}
	.navbar,
	.navbar.sticky {
		padding: 15px 30px !important;
	}
	.navbar li {
		display: none;
	}
	.navbar .btn-mobile-menu {
		visibility: initial;
		flex-grow: initial;
	}
	.star {
		height: 1.875em;
		width: 1.875em;
	}
	.hero-container .star-hero {
		top: -1.875em;
		left: -1.875em;
	}
	.hero-container .hero-sub-title {
		top: 4.3em;
	}
	.hero-container .hero-caption {
		width: 12em;
		margin-top: 10px;
	}

	.main .section-about {
		margin-top: 41px;
		grid-template-areas:
			'section-about__title'
			'section-about__img'
			'section-about__text'
			'section-about__bonus-text';
	}
	.main .section-about__img {
		height: 15.625em;
		width: 15.625em;
		margin: 0 auto;
	}
	.section-about__bonus-text {
		margin: 0 -30px 0 -30px;
	}
	.main .section-design {
		margin-top: 77px;
	}
	.section-design .main__title {
		margin-bottom: 34px;
	}
	.section-design .project-designs {
		gap: 20px;
	}
	.img-container-parent {
		height: 100%;
	}
	.section-design .img-container {
		height: 9.375em;
		width: 100%;
		transform: scale(1.5);
	}
	.section-design .img-container:not(a[href='#design-1']):hover {
		transform: scale(1.6);
	}

	.message-me h1 {
		margin: 100px 0 30px 0;
	}

	.footer {
		padding: 30px 30px;
		margin-top: 0;
	}
	.section-footer .line-star {
		gap: 13px;
	}
	.section-footer li {
		top: 0.3em;
	}
}
