/* Fade in */
@keyframes fade-in {
	from {
		visibility: hidden;
		opacity: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Fade out */
@keyframes fade-out {
	from {
		visibility: visible;
		opacity: 1;
	}
	99% {
		visibility: visible;
		opacity: 0;
	}
	to {
		visibility: hidden;
		opacity: 0;
	}
}

.fade-in {
	animation: fade-in 200ms ease-in-out forwards;
}
.fade-out {
	animation: fade-out 200ms ease-in-out forwards;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes rotate-reverse {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}
